import React from 'react'

import { MSlider } from './components/molecules/MSlider'

export const MissionList = ({ children, category = { name: '' } }) => (
  <div className="react-web--aster__container">
    {category?.name && (
      <h2 className="mt-3 mx-2 text-2xl text-gray-700 font-bold">{category.name}</h2>
    )}
    <MSlider startIndex={0} className="relative">
      {children}
    </MSlider>
  </div>
)
