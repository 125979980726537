import React, { useCallback, useState } from 'react'

import { OChat } from './OChat'
import { OMissionDetailsSidebar } from './OMissionDetailsSidebar'
import { OMissionDetailsHeader } from './OMissionDetailsHeader'
import { OMissionCover } from './OMissionCover'

export const OMissionDetails = ({
  mission = {},
  onUserAction,
  onClose,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  translations,
  shouldScrollItself = false,
  shouldDisplayCover = true,
  shouldDisplaySidebar = true,
  shouldDisplayCloseIcon = true,
}) => {
  const {
    title = '',
    steps = [],
    messages = [],
    completionMeta = null,
    coverImageUrl,
    coverImageMeta,
  } = mission

  const [displayCover, setDisplayCover] = useState(shouldDisplayCover)
  const [activeStep, setActiveStep] = useState(0)

  const startMission = useCallback(() => {
    setDisplayCover(false)
  }, [])

  const handleOnClose = useCallback(() => {
    if (onClose instanceof Function) {
      onClose()
    }
  }, [onClose])

  const handleStepUpdate = useCallback(() => {
    if (onStepUpdated instanceof Function) {
      onStepUpdated()
    }
  }, [onStepUpdated])

  const updateActiveStep = useCallback(
    newActiveStep => {
      setActiveStep(newActiveStep)
      handleStepUpdate()
    },
    [handleStepUpdate]
  )

  if (shouldScrollItself) {
    return (
      <div className="h-full max-w-3xl mx-auto pt-2 overflow-hidden">
        <OChat
          initialMessages={messages}
          completionMeta={completionMeta}
          onUserAction={onUserAction}
          onFinish={onFinish}
          onExit={onExit}
          onProgress={onProgress}
          onQuestion={onQuestion}
          shouldScrollItself={shouldScrollItself}
        />
      </div>
    )
  }

  if (displayCover) {
    return (
      <OMissionCover
        title={title}
        steps={steps}
        imageUrl={coverImageUrl}
        imageMeta={coverImageMeta}
        onStart={startMission}
        onClose={handleOnClose}
      />
    )
  }

  return (
    <div className="flex h-full">
      {shouldDisplaySidebar && (
        <OMissionDetailsSidebar
          title={title}
          imageUrl={coverImageUrl}
          imageMeta={coverImageMeta}
          steps={steps}
          activeStep={activeStep}
          onClose={handleOnClose}
          imageURL={coverImageUrl}
          translations={translations}
          shouldDisplayCloseIcon={shouldDisplayCloseIcon}
        />
      )}
      <div className="flex-1 max-w-3xl mx-auto pt-[72px] lg:pt-6">
        <OChat
          initialMessages={messages}
          completionMeta={completionMeta}
          onUserAction={onUserAction}
          onFinish={onFinish}
          onExit={onExit}
          onProgress={onProgress}
          onStepUpdated={updateActiveStep}
          onQuestion={onQuestion}
          shouldScrollItself={shouldScrollItself}
        />
      </div>
      <div className="lg:hidden fixed top-0 inset-x-0">
        <OMissionDetailsHeader
          title={title}
          onClose={handleOnClose}
          shouldDisplayCloseIcon={shouldDisplayCloseIcon}
        />
      </div>
    </div>
  )
}
