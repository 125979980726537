import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { PieChart } from 'react-minimal-pie-chart'
import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

const COLOR_PRIMARY = '#FFF'
const COLOR_SECONDARY = '#DAB6DB'

export const MChatInfographicPiechart = ({
  data,
  totalValue = 100,
  label,
  descriptionMarkdown,
}) => {
  const { user } = useUser()

  const pieChartValue = useMemo(() => parseInt(data[0]?.value), [data])

  return (
    <div className="min-w-[180px] py-3.5 px-4">
      <div className="w-32 mt-2 mx-auto mb-4">
        <PieChart
          data={[
            { color: COLOR_PRIMARY, value: totalValue - pieChartValue },
            { color: COLOR_SECONDARY, value: pieChartValue },
          ]}
          startAngle={-90}
          lengthAngle={-360}
          totalValue={totalValue}
          lineWidth={14}
          label={() => label}
          labelStyle={{
            fontSize: '24px',
            fontWeight: 'bold',
            x: '50%',
            y: '50%',
            fill: COLOR_PRIMARY,
          }}
          labelPosition={0}
          animate
          animationDuration={800}
          rounded
        />
      </div>
      <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
    </div>
  )
}

MChatInfographicPiechart.propTypes = {
  label: PropTypes.string.isRequired,
  descriptionMarkdown: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      // color: PropTypes.string,
    })
  ),
  totalValue: PropTypes.number,
}
