import { Fragment, useMemo } from 'react'
import useStore from './store/useStore'
import { useNavigate } from 'react-router-dom'

import { MissionList, MissionListItem } from '@aster/react-web'
import { Loading } from './components/Loading'

export function EdwinMissionList() {
  const navigate = useNavigate()
  const categories = useStore(store => store.topics)
  const categoriesSorted = useMemo(() => {
    if (!categories) {
      return []
    }
    return categories?.sort((a, b) => {
      if (a.priority !== undefined && b.priority !== undefined) {
        return a.priority - b.priority
      }

      return a.name.localeCompare(b.name)
    })
  }, [categories])

  if (categoriesSorted.length) {
    return (
      <>
        {categoriesSorted.map(category => (
          <Fragment key={category.id}>
            {category.missionsAndSeries && (
              <MissionList category={category}>
                {category.missionsAndSeries.map(mission => (
                  <MissionListItem
                    key={mission.id}
                    mission={mission}
                    onOpen={() => {
                      navigate(`/mission/${mission.id}`)
                    }}
                  />
                ))}
              </MissionList>
            )}
          </Fragment>
        ))}
      </>
    )
  }

  // if (missions.length) {
  //   return (
  //     <>
  //       {missionsAndSeries.map(mission => (
  //         <MissionListItem
  //           key={mission.id}
  //           mission={mission}
  //           onOpen={() => {
  //             navigate(`/mission/${mission.id}`)
  //           }}
  //         />
  //       ))}
  //     </>
  //   )
  // }

  return <Loading />
}
