import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// import { EdwinProvider } from '@edwin/react-sdk'
import { EdwinMission } from './EdwinMission'
import { EdwinMissionList } from './EdwinMissionList'
import useStore from './store/useStore'

function App() {
  const loadContent = useStore(store => store.loadContent)
  useEffect(() => {
    if (loadContent) {
      loadContent()
    }
  }, [loadContent])
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EdwinMissionList />} />
        <Route path="/mission/:id" element={<EdwinMission />}></Route>
      </Routes>
    </Router>
  )
}

export default App
