import { useNavigate, useParams } from 'react-router-dom'

import { Mission } from '@aster/react-web'
import { Loading } from './components/Loading'

import useStore from './store/useStore'
import { useMemo } from 'react'

export function EdwinMission() {
  const params = useParams()
  const navigate = useNavigate()

  const getMissionById = useStore(store => store.getMissionById)
  const missions = useStore(store => store.missions)

  const mission = useMemo(() => {
    if (missions?.length) {
      return getMissionById(params.id)
    }
    return null
  }, [getMissionById, missions?.length, params.id])

  if (!mission) {
    return <Loading />
  }

  return (
    <Mission
      mission={mission}
      user={{ firstName: 'Jane', lastName: 'Doe' }}
      onClose={capturedData => {
        // console.log('--------- closing', capturedData)
        navigate('/')
      }}
      onUserAction={(capturedData, action, nextMessageId) => {
        // console.log('------ on user action ------', action)
      }}
      onFinish={(capturedData, oaths) => {
        // console.log('on finish--------------------', capturedData)
        // console.log('---------- oaths taken', oaths)
        navigate('/')
      }}
    />
  )
}
