import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { AMarkdown } from '../atoms/AMarkdown'
import { MChatModal } from './MChatModal'
import { OInnerImageZoom } from '../organisms/OInnerImageZoom'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatInfographicIllustration = ({ illustrationFileName, descriptionMarkdown }) => {
  const { user } = useUser()
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const isPossibleToZoom = useMemo(() => {
    return illustrationFileName.slice(-4) !== '.gif'
  }, [illustrationFileName])

  return (
    <div>
      {illustrationFileName?.includes('https://') && (
        <img
          onClick={isPossibleToZoom ? openModal : null}
          src={illustrationFileName}
          className={classNames(
            'w-full h-full max-h-72 object-cover',
            isPossibleToZoom && 'cursor-pointer'
          )}
          alt="Illustration"
        />
      )}
      <div className="py-3.5 px-4">
        <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
      </div>
      {isPossibleToZoom && (
        <MChatModal isOpen={isOpen} onClose={closeModal}>
          <OInnerImageZoom src={illustrationFileName} onClose={closeModal} />
        </MChatModal>
      )}
    </div>
  )
}

MChatInfographicIllustration.propTypes = {
  descriptionMarkdown: PropTypes.string,
  illustrationFileName: PropTypes.string,
}
