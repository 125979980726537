import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getSanityImage, useAuthor } from '@edwin/react-sdk'

import ProgressiveImage from 'react-progressive-graceful-image'
import { ACloseIcon } from '../atoms/ACloseIcon'

export const OMissionDetailsSidebar = ({
  title = '',
  imageUrl,
  imageMeta,
  steps,
  activeStep = null,
  translations,
  shouldDisplayCloseIcon = true,
  onClose = () => {},
}) => {
  const { author } = useAuthor()

  const textTranslations = {
    author: 'with ',
    steps: "What we'll cover",
    ...translations,
  }

  return (
    <div className="hidden lg:block w-[300px]">
      <div className="fixed top-0 w-[300px] h-screen text-shark border-r border-sapling bg-sapling/10 overflow-hidden">
        {shouldDisplayCloseIcon && (
          <div className="relative">
            <div className="absolute top-2 left-2 z-40">
              <ACloseIcon onClick={onClose} />
            </div>
          </div>
        )}
        {imageUrl && (
          <ProgressiveImage
            placeholder={getSanityImage(imageUrl, {
              width: 100,
              height: 100,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
              quality: 20,
            })}
            src={getSanityImage(imageUrl, {
              width: 600,
              height: 600,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
            })}
          >
            {src => <img className="w-full h-72 object-cover m-0 p-0" src={src} alt="Author" />}
          </ProgressiveImage>
        )}
        <div
          className={classnames(
            'pl-5 pr-4 pb-4',
            !imageUrl && shouldDisplayCloseIcon ? 'pt-14' : 'pt-7'
          )}
        >
          {title && (
            <h2 className={classnames('text-2xl font-bold', author.name ? 'mb-2' : 'mb-5')}>
              {title}
            </h2>
          )}
          {author?.name && (
            <h5 className="mb-5 font-medium">
              {textTranslations.author}
              {author?.name}
            </h5>
          )}
          {steps?.length && (
            <>
              <h5 className="text-xs font-normal uppercase mb-3 tracking-wider">
                {textTranslations.steps}
              </h5>
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={classnames(
                    'h-full mb-2.5 p-3 text-base rounded-lg rounded-tl-none transition-colors',
                    activeStep >= index + 1 ? 'text-white bg-jagger/90' : 'text-white bg-jagger/60'
                  )}
                >
                  {step}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

OMissionDetailsSidebar.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  imageUrl: PropTypes.string,
  imageMeta: PropTypes.object,
  onClose: PropTypes.func,
  authorName: PropTypes.string,
  activeStep: PropTypes.number,
}
