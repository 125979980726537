import { create } from 'zustand'
import sanity from '@sanity/client'
import CONFIG from '../config/Config'
import _ from 'lodash'

const sanityClient = sanity({
  projectId: CONFIG.sanity.projectId,
  dataset: CONFIG.sanity.dataset,
  apiVersion: '2022-11-03', // use current UTC date - see "specifying API version"!
  token: CONFIG.sanity.token, // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
  ignoreBrowserTokenWarning: true,
})

const QUERY_NAMES = {
  topics: 'topics',
  authors: 'authors',
  topicSeries: 'topicSeries',
  missions: 'missions',
}

const MISSION_LIST_QUERY = `*[_type == 'userMission' && (published in ["published", "scheduled"]) && !(_id in path("drafts.**"))]{
  ...,
  "id": _id,
  "coverImageMeta": coverImage, 
  "coverImageUrl": coverImage.asset->url,
  "coverVideoUrl": {
    "portrait": coverVideoPortraitURL,
    "landscape": coverVideoLandscapeURL
  },
  "author":{"name": author->name, "avatarMeta": author->avatar, "avatarUrl": author->avatar.asset->url },
  "categories": securityCategories[]->{"id": _id, "name": name},
  "messages": coalesce(messages, []),
  "status": published,
  
} | order(priority asc)`
const PROGRAM_SNAPSHOTS_QUERY = `*[_type == "program" && !(_id in path("drafts.**"))] | order(priority asc)`
const ASSESSMENTS_QUERY = `*[_type == "assessment" && !(_id in path("drafts.**"))]`
const TOPICS_QUERY = `*[_type == "topic" && !(_id in path("drafts.**"))]{..., 
"coverImageUrl": coverImage.asset->url, 
"id": _id,
"missionsAndSeries": missionsAndSeries[] -> {
    ..., 
    "id": _id, 
    "coverImageMeta": coverImage, 
    "coverImageUrl": coverImage.asset->url
  }
}`

const AUTHOR_QUERY = `*[_type == "author" && _id == "c60b507a-e8c3-46a8-b386-76af6e26a1e2"]{
  name, 
  "avatarMeta": avatar,
  "avatarUrl": avatar.asset->url
}`

const TOPIC_SERIES_QUERY = `*[_type == "topicSeries" && !(_id in path("drafts.**"))]`

export const useStore = create((set, get) => ({
  missions: [],
  topics: [],
  series: [],
  authors: [],
  isContentLoaded: false,
  addQueryToLoaded: queryName => {
    const loadedQueriesCopy = { ...get().loadedQueries }
    loadedQueriesCopy[queryName] = true

    set({ loadedQueries: loadedQueriesCopy })
  },
  loadContent: async () => {
    sanityClient.fetch(MISSION_LIST_QUERY, {}).then(missions => {
      const parsedMissions = missions.map(el => ({
        ...el,
        messages: JSON.parse(el.messages),
      }))
      set({ missions: parsedMissions })

      get().addQueryToLoaded(QUERY_NAMES.missions)
      // console.log('### DONE LOADING ', 'missions')
    })

    sanityClient.fetch(TOPICS_QUERY, {}).then(results => {
      set({ topics: results })
      get().addQueryToLoaded(QUERY_NAMES.topics)
      // console.log('### DONE LOADING ', 'topics')
    })

    sanityClient.fetch(AUTHOR_QUERY, {}).then(results => {
      set({ assessmentAuthor: results[0] })
      get().addQueryToLoaded(QUERY_NAMES.authors)
      // console.log('### DONE LOADING ', 'author')
    })
  },

  isAllLoaded: () => {
    /**
     * For some reason simle array changes didn't work
     */
    return Object.keys(get().loadedQueries).length === Object.keys(QUERY_NAMES).length
  },

  getMissionById: missionId => {
    return _.find(get().missions, { id: missionId })
  },
}))

export default useStore
