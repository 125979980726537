import React, { forwardRef } from 'react'
import './index.scss'
import { getSanityImage } from '@edwin/react-sdk'

export const MissionListItem = forwardRef(
  ({ mission, onOpen = () => {}, isCoverImage = true }, ref) => {
    const { title, author, coverImageUrl, coverImageMeta } = mission

    const coverImage = getSanityImage(coverImageUrl, {
      width: 240,
      height: 320,
      crop: coverImageMeta?.crop,
      hotspot: coverImageMeta?.hotspot,
    })

    return (
      <div ref={ref} className="react-web--aster__container">
        <div
          onClick={onOpen}
          className="relative w-60 h-80 mt-2 mx-2.5 mb-10 py-3 px-5 rounded-md shadow-lg overflow-hidden cursor-pointer transition-transform duration-200 hover:scale-105"
        >
          <div className="absolute inset-0 bg-gradient-to-t from-neutral-700/80 to-transparent">
            {isCoverImage && <img src={coverImage} alt="" className="absolute -z-10" />}
          </div>
          <div className="relative flex flex-col justify-end h-full text-neutral-50">
            <p className="text-xl font-bold">{title}</p>
            <div className="w-14 h-0.5 my-1.5 bg-neutral-50/70 rounded-full" />
            <p className="text-sm">{author?.name}</p>
          </div>
        </div>
      </div>
    )
  }
)
