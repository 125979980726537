import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MChatModal } from './MChatModal'
import { OInnerImageZoom } from '../organisms/OInnerImageZoom'

export const MChatImage = ({ title, description, src }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const isPossibleToZoom = useMemo(() => {
    return src.slice(-4) !== '.gif'
  }, [src])

  return (
    <div className={classNames((title || description) && 'py-3.5 px-4')}>
      {title && <h4 className="text-xl mb-2">{title}</h4>}
      <div
        onClick={isPossibleToZoom ? openModal : null}
        className={classNames(isPossibleToZoom && 'cursor-pointer')}
      >
        <img src={src} alt="" className="w-full max-h-72" />
      </div>
      {description && <p className="mt-2">{description}</p>}
      {isPossibleToZoom && (
        <MChatModal isOpen={isOpen} onClose={closeModal}>
          <OInnerImageZoom src={src} onClose={closeModal} />
        </MChatModal>
      )}
    </div>
  )
}

MChatImage.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}
