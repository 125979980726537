import React, { useRef, useCallback } from 'react'
import classNames from 'classnames'

import { AMarkdown } from '../atoms/AMarkdown'

export const MChatUserOption = ({ label, chosenOption, index, onClick = () => {} }) => {
  const buttonRef = useRef(null)

  const handleClick = useCallback(() => {
    buttonRef.current.blur() // removing focus
    onClick()
  }, [onClick])

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      tabIndex={chosenOption === index ? -1 : 0}
      className={classNames(
        'py-3.5 px-4 text-sm font-bold text-shark border hover:bg-sapling hover:border-sapling rounded-full transition cursor-pointer',
        'focus:outline-none focus:ring-2 focus:ring-sapling focus:ring-offset-1 focus:bg-sapling focus:border-sapling',
        chosenOption === null
          ? 'mb-2.5 last:mb-0 border-shark'
          : chosenOption === index
          ? 'bg-sapling border-sapling animate-border-radius'
          : 'hidden'
      )}
    >
      <AMarkdown>{label}</AMarkdown>
    </button>
  )
}
