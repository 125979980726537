import { useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import { MChatAudioBtn } from './MChatAudioBtn'
import { MChatAudioTranscript } from './MChatAudioTranscript'
import { MChatModal } from './MChatModal'
import { OInnerImageZoom } from '../organisms/OInnerImageZoom'
import { useAudio } from '../../utils/useAudio'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatAudio = ({ coverImage, description, transcriptMarkdown, audiofileLink }) => {
  const { user } = useUser()
  const { status, leftTimeLabel, toggleAudio } = useAudio(audiofileLink)
  const [isImageZoomOpen, setIsImageZoomOpen] = useState(false)

  const openImageZoom = useCallback(() => {
    setIsImageZoomOpen(true)
  }, [setIsImageZoomOpen])

  const closeImageZoom = useCallback(() => {
    setIsImageZoomOpen(false)
  }, [setIsImageZoomOpen])

  const isPossibleToZoom = useMemo(() => {
    return coverImage && coverImage.slice(-4) !== '.gif'
  }, [coverImage])

  return (
    <div>
      {coverImage && (
        <div
          onClick={isPossibleToZoom ? openImageZoom : null}
          className={classNames(isPossibleToZoom && 'cursor-pointer')}
        >
          <img src={coverImage} alt="" className="w-full max-h-72 object-cover" />
        </div>
      )}
      <div className="py-4 px-4">
        {description && (
          <ReactMarkdown
            components={{
              a: ({ href, children }) => (
                <a className="underline " href={href} target="_blank" rel="noreferrer">
                  {children[0]}
                </a>
              ),
            }}
          >
            {_.template(description)({ user })}
          </ReactMarkdown>
        )}
        <div
          className={classNames(
            'flex',
            transcriptMarkdown ? 'justify-between' : 'justify-center',
            (coverImage || description) && 'mt-3'
          )}
        >
          <MChatAudioBtn
            playbackState={status}
            playbackTimeToFinish={leftTimeLabel}
            onClick={toggleAudio}
          />
          {transcriptMarkdown && <MChatAudioTranscript transcriptMarkdown={transcriptMarkdown} />}
        </div>
      </div>
      {isPossibleToZoom && (
        <MChatModal isOpen={isImageZoomOpen} onClose={closeImageZoom}>
          <OInnerImageZoom src={coverImage} onClose={closeImageZoom} />
        </MChatModal>
      )}
    </div>
  )
}
