import React from 'react'
import { useChatComponents, useMessages, customTypingMethods } from '@edwin/react-sdk'

import { MChatMsgWrapper } from '../molecules/MChatMsgWrapper'

export const OChatMessage = ({ msg, scrollChatBottom }) => {
  const [message, isTyping] = useMessages({
    msg,
    scrollChatBottom,
    customTypingMethods,
  })

  const componentName = msg.type === 'bot-component' ? msg.componentName : msg.type
  const { ChatComponent } = useChatComponents(componentName)

  const componentOptions = {
    ...(message.componentOptions && message.componentOptions),
    ...(message.msg && { msg: message.msg }),
    ...(message.options && { options: message.options }),
    ...(message.onProceed && { onProceed: message.onProceed }),
    ...(message.componentName && { componentName: message.componentName }),
    ...(message.type !== 'bot-component' && { componentName: message.type }),
  }

  if (componentName === 'user-options') {
    return <ChatComponent {...componentOptions} />
  }

  return (
    <MChatMsgWrapper first={msg.first} strong={msg.strong} isTyping={isTyping}>
      <ChatComponent {...componentOptions} />
    </MChatMsgWrapper>
  )
}
