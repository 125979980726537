import React from 'react'
import classnames from 'classnames'

const animateDelays = ['animate-delay-200', 'animate-delay-300', 'animate-delay-400']

export const ATypingIndicator = () => (
  <div className="flex items-center mt-4 mb-1 py-3 px-3.5">
    {[...Array(3).keys()].map(delayIndex => (
      <div
        key={delayIndex}
        className={classnames(
          'w-1 h-1 mr-1 last:mr-0 bg-white rounded-full animate-typing-dot',
          `${animateDelays[delayIndex]}`
        )}
      />
    ))}
  </div>
)
