import React from 'react'

import ReactMarkdown from 'react-markdown'

export const AMarkdown = ({ children, className }) => {
  return (
    <ReactMarkdown
      className={className}
      components={{
        a: ({ href, children }) => (
          <a className="underline " href={href} target="_blank" rel="noreferrer">
            {children[0]}
          </a>
        ),
        ol: ({ children }) => {
          return <ol className="my-1 list-decimal list-inside">{children}</ol>
        },
        ul: ({ children }) => {
          return <ul className="my-1">{children}</ul>
        },
        li: ({ children }) => {
          return (
            <li className="-mx-4 px-4 border-b border-[#DAB6DB] pb-2 mb-2 last:mb-0">{children}</li>
          )
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}
