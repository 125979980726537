import { Play, Pause } from 'react-feather'

export const MChatAudioBtn = ({ playbackState, playbackTimeToFinish, onClick = () => {} }) => (
  <div
    onClick={onClick}
    className="flex justify-center items-center py-1 px-2.5 border border-white rounded-full cursor-pointer"
    style={{
      textWrap: 'nowrap',
    }}
  >
    {playbackState === 'buffering' && (
      <>
        <svg
          className="animate-spin h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <div className="ml-1 text-sm text-white">Buffering</div>
      </>
    )}
    {playbackState === 'ready' && (
      <>
        <Play size={16} color="white" />
        <div className="ml-0.5 text-sm text-white">{playbackTimeToFinish}</div>
      </>
    )}
    {playbackState === 'pause' && (
      <>
        <Play size={16} color="white" />
        <div className="ml-0.5 text-sm text-white">{playbackTimeToFinish} left</div>
      </>
    )}
    {playbackState === 'playing' && (
      <>
        <Pause size={16} color="white" />
        <div className="ml-0.5 text-sm text-white">Pause</div>
      </>
    )}
  </div>
)
