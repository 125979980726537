import { useCallback, useEffect, useState } from 'react'
import { useSummary } from '@edwin/react-sdk'
import _ from 'lodash'

import { AButton } from '../atoms/AButton'
import { AMarkdown } from '../atoms/AMarkdown'
import { MChatMissionSummaryMedal } from './MChatMissionSummaryMedal'
import _ from 'lodash'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatMissionSummary = ({
  completionMeta,
  triggerConfetti = () => {},
  onFinish = () => {},
}) => {
  const {
    points = 0,
    pointsForCompletion = 0,
    isCurrent = false,
    translations = {
      congrats: 'Congratulations!',
      youEarned: 'You earned',
      points: '{{pointsForCompletion}} points.',
      totalPoints: '{{summaryPoints}} total points',
      ctaText: 'Finish',
    },
  } = useSummary()

  const [summaryPoints, setSummaryPoints] = useState(
    isCurrent ? points + pointsForCompletion : points
  )
  const [wasConfetti, setWasConfetti] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const handleOnFinish = useCallback(() => {
    if (!isFinished) {
      onFinish()
      setIsFinished(true)
    }
  }, [isFinished, onFinish])

  useEffect(() => {
    if (!wasConfetti) {
      setTimeout(() => {
        triggerConfetti()
        setWasConfetti(true)
      }, 500)
    }
  }, [triggerConfetti, wasConfetti])

  return (
    <div className="py-3 px-3.5">
      <div className="flex mb-2">
        <MChatMissionSummaryMedal />
        <div className="ml-2">
          <div className="text-lg leading-5 font-semibold">{translations.congrats}</div>
          {completionMeta.description && (
            <div className="mt-3 opacity-90">
              <AMarkdown>{completionMeta.description}</AMarkdown>
            </div>
          )}
        </div>
      </div>
      <AButton className="w-full" onClick={handleOnFinish} disabled={isFinished}>
        {translations.ctaText}
      </AButton>
    </div>
  )
}
